:root {
  --color-body: #ffffff;
  --color-text: #222222;
  --color-primary: #c82027;
  --color-secondary: #004F9E;
  --color-success: #44a579;
  --color-button: #eee;

  --text-size-body: 1.6rem;
  --text-size-1: 6rem;
  --text-size-2: 4rem;
  --text-size-3: 3rem;
  --text-size-4: 2.4rem;
  --text-size-5: 2rem;
  --text-size-6: 1.4rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-body:  #222;
    --color-text: #eee;
    --color-button: #333;
  }
}

html{
  background-color: var(--color-body);
  box-sizing: border-box;
  font-size: 62.5%
}
*,*::after,*::before{
  box-sizing: inherit
}
html,body{
  max-width: 100%;
  overflow-x: hidden
}

body{
  background-color:var(--color-body);
  color:var(--color-text);
  /* font-family:var(--font-body); */
  font-size:var(--text-size-body);
  font-weight:400;
  line-height:1.4;
  letter-spacing:.005em
}
